var stickyHeader = true;
var transformHomepageDonationAmountImages = true;
var flyoutNav = true;
// var fullscreenNav = true;
// var homeQuickGivingPreFooter = false;
// var countUpStats = true;
// var countUpStatsDuration = 1500;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video


$("body[class*='Post'] .headerText h1.title").wrapInner("<span class='text-box'></span>");
